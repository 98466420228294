import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getStaticImageUrl } from 'client/utils/image-helpers';

import 'client/site-modules/shared/components/financing/components/benefits-with-icons/benefits-with-icons.scss';

export const BenefitsWithIcons = ({ benefits, isMobile, benefitsClass, benefitItemClass, benefitCopyClass }) => (
  <ul className={classNames('financing-benefits list-unstyled', benefitsClass)}>
    {benefits.map((benefit, index) => {
      const { icon, width, text } = benefit;
      const benefitNum = index + 1;
      return (
        <li key={`benefit_${benefitNum}`} className="benefit-item">
          <div className={benefitItemClass}>
            <div className="benefit-icon mb-0_25">
              <img src={getStaticImageUrl(icon)} alt="" loading="lazy" width={width} />
            </div>
            <div className={classNames('benefit-copy', benefitCopyClass, { 'font-weight-medium': isMobile })}>
              {text}
            </div>
          </div>
        </li>
      );
    })}
  </ul>
);

BenefitsWithIcons.propTypes = {
  benefits: PropTypes.arrayOf(PropTypes.shape({})),
  isMobile: PropTypes.bool,
  benefitsClass: PropTypes.string,
  benefitItemClass: PropTypes.string,
  benefitCopyClass: PropTypes.string,
};

BenefitsWithIcons.defaultProps = {
  benefits: [],
  isMobile: false,
  benefitsClass: 'd-flex justify-content-between',
  benefitItemClass: 'd-flex flex-column align-items-center',
  benefitCopyClass: '',
};
