export const CPO_JUMP_LINK_LOCATION = 'certified-pre-owned';

export const NAVIGATION_IDS = {
  OVERVIEW: 'overview',
  DETAILS: 'details',
  SUMMARY: 'summary',
  HISTORY: 'history',
  EV_INSIGHTS: 'ev-insights',
  PHEV_INSIGHTS: 'phev-insights',
  OPTIONS: 'options',
  FEATURES: 'features',
  PRICE: 'price',
  CALCULATOR: 'calculator',
  DEALER: 'dealer',
  REVIEWS: 'reviews',
  WARRANTY: 'warranty',
};

export const NAVIGATION_TITLES = {
  [NAVIGATION_IDS.OVERVIEW]: 'Overview',
  [NAVIGATION_IDS.DETAILS]: 'Details',
  [NAVIGATION_IDS.SUMMARY]: 'Summary',
  [NAVIGATION_IDS.HISTORY]: 'History',
  [NAVIGATION_IDS.EV_INSIGHTS]: 'EV Insights',
  [NAVIGATION_IDS.PHEV_INSIGHTS]: 'Plug-In Hybrid Insights',
  [NAVIGATION_IDS.OPTIONS]: 'Options',
  [NAVIGATION_IDS.FEATURES]: 'Features',
  [NAVIGATION_IDS.PRICE]: 'Price',
  [NAVIGATION_IDS.CALCULATOR]: 'Payments',
  [NAVIGATION_IDS.WARRANTY]: 'Warranty',
  [NAVIGATION_IDS.DEALER]: 'Dealer',
  [NAVIGATION_IDS.REVIEWS]: 'Reviews',
};
